<template>
    <div>Redirecting...</div>
  </template>

  <script>
  export default {
    name: 'RootRedirect',
    created() {
      const targetOrg = localStorage.getItem("organization") || 'default'
      this.$router.replace({ name: 'chat', params: { organization: targetOrg } })
    }
  }
  </script>
